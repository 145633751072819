import {Splide} from '@splidejs/splide';

let sliderSlider = document.getElementsByClassName('slider-slider');

document.addEventListener('DOMContentLoaded', function () {
    for (let i = 0; i < sliderSlider.length; i++) {
        let splide = new Splide(sliderSlider[i], {
            type: 'slide',
            perMove: 1,
            rewind: true,
            gap: 24,
            pagination: false,
            arrows: false,
            width: '100%',
            perPage: 3,
            breakpoints: {
                1023: {
                    perPage: 2,
                },
                640: {
                    perPage: 1,
                },
            }
        });

        let bar = splide.root.querySelector('.splide-progress-bar');

        splide.on('mounted move', function () {
            let end = splide.Components.Controller.getEnd() + 1;
            bar.style.width = String(100 * (splide.index + 1) / end) + '%';
        });

        //Set overflow when slider is active
        splide.on('mounted', function () {
            let total = splide.length;
            let track = splide.root.querySelector('.splide__track');
            let progress = splide.root.querySelector('.progress');
            track.classList.add('hasOverflow');

            if (total < 4) {
                progress.classList.add('hide-for-xlarge');
            }
        });

        splide.mount();
    }
});
