var hamburger = document.querySelector('.hamburger'),
    menu = document.querySelector('.navigation'),
    header = document.querySelector('.header'),
    body = document.body

//Toggle hamburger menu
function toggleMenu() {
    console.log('fff');
    hamburger.classList.toggle('hamburger--open');
    menu.classList.toggle('navigation--open');
    header.classList.toggle('menu-open');
    body.classList.toggle('menu-open');
}

hamburger.addEventListener('click', toggleMenu);
