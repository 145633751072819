clickableBlock = document.querySelectorAll('.clickable');

if (clickableBlock) {
    clickableBlock.forEach(function (el) {
        el.addEventListener('click', function (e) {
            let url = this.getElementsByClassName('url')[0].href;
            let target = this.getElementsByClassName('url')[0].target;

            if (target === '_blank') {
                window.open(url, '_blank');
            } else {
                window.open(url, '_self');
            }
        });
    });
}

copyUrl = document.querySelectorAll('.copyurl');

if (copyUrl) {
    copyUrl.forEach(function (el) {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            copyText(this);
        });
    });
}

function copyText(element) {
    var textToCopy = element.href;
    var myTemporaryInputElement = document.createElement('input');
    myTemporaryInputElement.type = 'text';
    myTemporaryInputElement.value = textToCopy;
    document.body.appendChild(myTemporaryInputElement);
    myTemporaryInputElement.select();
    document.execCommand('Copy');
    document.body.removeChild(myTemporaryInputElement);
}

